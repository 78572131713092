<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <v-row no-gutters class="d-flex align-center justify-start mt-1">
                <v-col class="d-flex justify-start">
                    <div class="d-flex align-start mr-4">
                        <v-icon x-large color="primary" style="margin-top: 6px;">
                            {{ tableIcon }}
                        </v-icon>
                    </div>
                    <div class="d-flex flex-column align-start">
                        <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                        <div class="mb-2 d-flex align-center">
                            <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>
                            <span class="text-caption">{{ tableDescription }}</span>
                        </div>
                    </div>
                </v-col>

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="dialogGridNewItem = true"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Novo item</span>
                </v-tooltip>

                <!-- <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || event.id_event_type === 4"
                            @click="updateMonthsDialog = true"
                        >
                            <v-icon>mdi-update</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar meses realizados</span>
                </v-tooltip>

                <v-dialog v-model="updateMonthsDialog" max-width="450px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="title">Você deseja realizar essa atualização?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="updateMonthsDialog = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="updateMonths()" :loading="loadingUpdateMonths">Continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog> -->

                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn color="primary" class="mb-2 mr-4 pa-0"
                            v-on="{ ...tooltip}"
                            min-width="48px"
                            @click="setRefresh()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="refresh(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mb-2 mr-4 pa-0"
                            @click="save()"
                            v-bind="attrs"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'warning' : 'primary'"
                            :disabled="disableActionButtons"
                        >
                            <v-icon> {{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="mb-2 mr-4 pa-0"
                            v-bind="attrs"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="mb-2 mr-4 pa-0"
                            v-bind="attrs"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn color="primary" class="mb-2 mr-4 pa-0"
                            v-on="{ ...tooltip}"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="mb-2 pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="warning" text @click="$router.push('/')" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="save(), $router.push('/')">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-row>

            <v-divider class="mt-1 mb-2"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center pt-3">
                <v-col class="d-flex justify-start">
                    <v-autocomplete
                        name="event"
                        label="Evento"
                        :items="options.find(option => option.column === 'id_event').items"
                        item-value="text"
                        return-object
                        class="pa-0 mr-4"
                        v-model="event"
                        @change="setSelectEvent()"
                        @click="backupSelectEvent = event"
                        :loading="event ? false : true"
                        style="max-width: 300px !important; z-index: 1000 !important;"
                    />

                    <v-dialog v-model="dialogSelectEvent" max-width="850px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDialogSelectEvent()" class="mr-4">Cancelar</v-btn>
                                <v-btn color="warning" text @click="selectEvent(false)" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="selectEvent(true)">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-divider vertical class="mr-4 mb-4"></v-divider>
                    
                    <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                    <div class="d-flex mt-1">
                        <v-checkbox
                            label="Mês"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1month"
                            @click="showAllMonths(1)"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="!year1month"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="260px">
                                <!-- @change="setYear1Months($event)" -->
                                <v-select
                                    v-model="year1months"
                                    :items="months"
                                    label="Escolha os meses"
                                    :item-text="item => item.text"
                                    @change="showMonth(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1months.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1months = months, showAllMonths(1)"
                                        :disabled="year1months.length === 12"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Tri"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1quarter"
                            @click="showAllQuarters(1)"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="!year1quarter"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year1quarters"
                                    :items="quarters"
                                    label="Escolha os trimestres"
                                    :item-text="item => item.text"
                                    @change="showQuarter(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1quarters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1quarters = quarters, showAllQuarters(1)"
                                        :disabled="year1quarters.length === 4"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Sem"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1semester"
                            @click="showAllSemesters(1)"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="!year1semester"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year1semesters"
                                    :items="semesters"
                                    label="Escolha os semestres"
                                    :item-text="item => item.text"
                                    @change="showSemester(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1semesters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1semesters = semesters, showAllSemesters(1)"
                                        :disabled="year1semesters.length === 2"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Ano 1"
                            :ripple="false"
                            class="pa-0 ma-0 mr-4"
                            v-model="startingYear"
                            @click="showYear(1)"
                        />
                    </div>

                    <v-divider vertical class="mr-4 mb-4"></v-divider>

                    <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                    <div class="d-flex mt-1">
                        <v-checkbox
                            label="Mês"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2month"
                            @click="showAllMonths(2)"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="!year2month"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2months"
                                    :items="months"
                                    :item-text="item => item.text"
                                    @change="showMonth(2)"
                                    label="Escolha os meses"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2months.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2months = months, showAllMonths(2)"
                                        :disabled="year2months.length === 12"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Tri"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2quarter"
                            @click="showAllQuarters(2)"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="!year2quarter"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2quarters"
                                    :items="quarters"
                                    label="Escolha os trimestres"
                                    :item-text="item => item.text"
                                    @change="showQuarter(2)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2quarters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2quarters = quarters, showAllQuarters(2)"
                                        :disabled="year2quarters.length === 4"

                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Sem"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2semester"
                            @click="showAllSemesters(2)"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="!year2semester"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2semesters"
                                    :items="semesters"
                                    label="Escolha os semestres"
                                    :item-text="item => item.text"
                                    @change="showSemester(2)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2semesters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2semesters = semesters, showAllSemesters(2)"
                                        :disabled="year2semesters.length === 2"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Ano 2"
                            :ripple="false"
                            class="pa-0 ma-0 mr-4"
                            v-model="laterYear"
                            @click="showYear(2)"
                        />
                    </div>
                </v-col>
            </v-row>

            <v-row no-gutters class="d-flex justify-start align-center mt-2">
                <v-autocomplete
                    name="id_purpose"
                    label="Propósito"
                    :items="options.find(option => option.column === 'id_purpose').items"
                    item-value="text"
                    return-object
                    class="pa-0 mr-4"
                    v-model="purpose"
                    @change="setSelectPurpose()"
                    @click="backupSelectPurpose = purpose"
                    :loading="purpose ? false : true"
                    style="max-width: 300px !important; z-index: 1000 !important;"
                />

                <v-dialog v-model="dialogSelectPurpose" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDialogSelectPurpose()" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="selectPurpose(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="selectPurpose(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-autocomplete
                    name="id_cost_center"
                    label="Centro de custo"
                    :items="options.find(option => option.column === 'id_cost_center').items"
                    item-value="text"
                    return-object
                    class="pa-0 mr-4"
                    v-model="costCenter"
                    @change="setSelectCostCenter()"
                    @click="backupSelectCostCenter = costCenter"
                    :loading="costCenter ? false : true"
                    style="max-width: 300px !important; z-index: 1000 !important;"
                />

                <v-dialog v-model="dialogSelectCostCenter" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDialogSelectCostCenter()" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="selectCostCenter(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="selectCostCenter(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 240" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 240" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else :height="windowSize.y - 64 - 240" class="rounded-b px-4">
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="objectData"
                :settings="settings"
                :columns="columns"
                :colHeaders="colHeaders"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :manualColumnResize="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                :comments="true"
                :customBorders="true"
                fixedColumnsLeft="2"
                width="100%"
                height="100%"
                :columnHeaderHeight="50"
                :rowHeaderWidth="40"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <CommentsMenu
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="payload"
        />

        <!-- <v-dialog v-model="dialogSolver" max-width="900px"
            style="z-index: 5000 !important;"
        >
            <v-card>
                <v-card-title class="headline neutral">
                    <span class="headline">Atingir meta</span>
                </v-card-title>
                <v-card-text>
                    <ZnapSolverDialog 
                        :solver="solverProps"
                    />
                </v-card-text>
            </v-card>
        </v-dialog> -->

        <v-dialog v-model="dialogGridNewItem" max-width="900px" style="z-index: 5000 !important;">
            <ZnapGridNewItemDialog
                @closeDialog="dialogGridNewItem = false"
                @insertNewRow="insertNewRow"
            />
        </v-dialog>

        <div v-if="dialogSolver" style="z-index: 5000 !important;">
            <ZnapSolverDialog 
                :solver="solverProps"
                :data="objectData"
                @close="closeDialogSolver"
                @confirm="runSolver"
            />
        </div>
    </v-layout>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import CommentsMenu from '@/components/znap/CommentsMenu.vue'
import ZnapSolverDialog from '@/components/znap/ZnapSolverDialog.vue'
import ZnapGridNewItemDialog from '@/components/znap/ZnapGridNewItemDialog.vue'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapSolverDialog, ZnapGridNewItemDialog
    },

    props: {
        options: { type: Array, required: true },
        tableName: { type: String, required: true },
        tableIcon: { type: String },
        tableDescription: { type: String }
    },

    data() {
        return {
            // Hot Table
            objectData: [],
            settings: {
                formulas: {
                    engine: HyperFormula,
                },
                afterChange: (change) => {
                    if (change) {
                        this.editedRows.push(change[0][0])
                    }
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData);
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                cells: (row) => {
                    var cellProperties = {}

                    const stylizedRow = this.stylizedRows[row] || { index: -1 }

                    if (row === stylizedRow.index) {
                        cellProperties.className = stylizedRow.className

                        cellProperties.numericFormat = {
                            pattern: stylizedRow.format,
                            culture: 'pt-BR'
                        }

                        if (stylizedRow.readOnly) {
                            cellProperties.readOnly = stylizedRow.readOnly
                        }
                    }

                    return cellProperties
                }
            },
            columns: [],
            colHeaders: [],
            rowHeaders: [],
            stylizedRows: [],
            hiddenColumns: {},
            hiddenRows: { rows: [], indicators: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
            ],

            dialogSolver: false,
            solverProps: {
                columnName: null,
                column: null,
                row: null,
                rowProps: null,
                targetRow: null,
                targetValue: null,
                changeRow: null,
            },

            dialogGridNewItem: false,
            
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            // Options
            payload: {
                id_event: null,
                id_purpose: null,
                id_cost_center: null,
                id_account_group_version: null
            },
            event: null,
            purpose: null,
            costCenter: null,
            accountGroupVersion: null,
            year1: null,
            year2: null,

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            yearMonthInputStart: null,

            // Unsaved changes
            editedRows: [],
            dialogCloseGrid: false,
            dialogRefreshGrid: false,
            dialogSelectEvent: false,
            backupSelectEvent: null,
            dialogSelectPurpose: false,
            backupSelectPurpose: null,
            dialogSelectCostCenter: false,
            backupSelectCostCenter: null,

            windowSize: { x: 0, y: 0 },
            loading: true,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },

            hotInstance: null,

            drawer: false,
            exporting: false,
            importing: false,

            updateMonthsDialog: false,
            loadingUpdateMonths: false,
        }
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },
        
    destroyed() {
        this.removePreventCloseHandler()
    },

    computed: {
        disableActionButtons() {
            let disableActionButtons = false
            for (let key in this.payload) {
                if (this.payload[key] === null) {
                    disableActionButtons = true
                }
            }

            return disableActionButtons
        },

        unsavedChanges() {
            if (this.editedRows.length > 0) {
                return true
            } else {
                return false
            }
        },
    },

    async beforeMount() {
        try {
            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_module",
                        operator: "=",
                        value: 11
                    },
                ]
            }

            const eventOption = this.options.find(option => option.column === 'id_event')
            const eventRes = await this.$http.post(eventOption.endpoint[0] + eventOption.endpoint[1] + '/list-options', { filter })
            if (eventRes.data.rows.length) {
                eventRes.data.rows.forEach((row, index) => {
                    eventOption.items.push(row)
                    if (index === 0) {
                        this.event = row
                        this.payload.id_event = this.event.id
                        this.payload.id_account_group_version = row.id_account_group_version
                        this.year1 = row.fiscal_year
                        this.yearMonthInputStart = row.year_month_input_start
                        this.startingMonthIndex = parseInt((row.year_month_input_start).toString().slice(4, 6)) - 1
                        this.year2 = this.year1 + 1
                    }
                })

                this.setDateColumns()

                filter = {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_purpose_type",
                            operator: "=",
                            value: 2 // Investimentos
                        },
                        {
                            AndOr: "AND",
                            column: "matrix",
                            operator: "=",
                            value: 0
                        }
                    ]
                }

                const purposeOption = this.options.find(option => option.column === 'id_purpose')
                const purposeRes = await this.$http.post(purposeOption.endpoint[0] + purposeOption.endpoint[1] + '/list-options', { filter })
                if (purposeRes.data.rows.length) {
                    purposeRes.data.rows.forEach((row, index) => {
                        purposeOption.items.push(row)
                        if (index === 0) {
                            this.purpose = row
                            this.payload.id_purpose = this.purpose.id
                        }
                    })
                    
                    filter = {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_purpose",
                                operator: "=",
                                value: this.payload.id_purpose
                            }
                        ]
                    }

                    const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
                    const costCenterRes = await this.$http.post(costCenterOption.endpoint[0] + costCenterOption.endpoint[1] + '/list-options', { filter })
                    if (costCenterRes.data.rows.length) {
                        costCenterRes.data.rows.forEach((row, index) => {
                            costCenterOption.items.push(row)
                            if (index === 0) {
                                this.costCenter = row
                                this.payload.id_cost_center = this.costCenter.id_cost_center
                            }
                        })

                        return this.loadGrid(this.payload)
                    } else {
                        this.noCostCentersFound()
                    }
                } else {
                    this.noPurposesFound()
                }
            } else {
                this.noEventsFound()
            }
        } catch (err) {
            this.$fnError(err)
        }
    },

    methods: {
        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges) {
                e.preventDefault()
                e.returnValue = ''
            }
        },
        
        closeDialogSolver() {
            this.dialogSolver = false
        },

        // runSolver(payload) {
        //     let result = this.solver(payload.column, payload.targetRow, payload.targetValue, payload.changeRow)
        // },

        // solver(column = 0, targetRow = 0, targetValue = 0, changeRow = 0){
        //     function ruleOf3 (initValChange, currTargetVal, initValTarget){
        //         if(initValTarget === 0 ) return 0
        //         return ( initValChange * currTargetVal ) / initValTarget
        //     }

        //     // TODO: ver com ademir se é assim que posso me referenciar a table, par apode referenciar as celulas
        //     const table = this.hotInstance

        //     let initialValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
        //     let initialValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

        //     let calculatedChangeRow = ruleOf3( initialValueFromChangeRow , targetValue , initialValueFromTargetRow )
        //     if(!calculatedChangeRow) return { interations: 0, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }

        //     table.setDataAtCell(changeRow, column, calculatedChangeRow)

        //     // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

        //     let condition = true
        //     let interations = 0

        //     while (condition) {
        //         interations++
        //         let newValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
        //         let newValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

        //         let dif = targetValue - newValueFromTargetRow

        //         if( Math.abs(dif) <= 0.5 ){
        //             condition = false
        //             table.setDataAtCell(changeRow, column, initialValueFromChangeRow)
        //             return { interations, newValueFromChangeRow, newValueFromTargetRow }
        //         }

        //         // TODO: testar o algoritimo e ajustar os incrementos e decrementos
        //         if( dif > 0 && dif <= 1 ) newValueFromChangeRow = newValueFromChangeRow + 0.1
        //         if( dif > 1 ) newValueFromChangeRow = newValueFromChangeRow + 1
        //         if( dif < 0 && dif >= -1 ) newValueFromChangeRow = newValueFromChangeRow - 0.1
        //         if( dif < -1 ) newValueFromChangeRow = newValueFromChangeRow - 1

        //         table.setDataAtCell(changeRow, column, newValueFromChangeRow)
        //         // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

        //         if(interations > 10000) condition = false
        //     }

        //     return { interations, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }
        // },

        async loadGrid(payload) {
            this.loading = true
            let loadGrid = true
            this.editedRows = []

            this.noDataMessage = null
            this.noDataBtn = { path: null, text: null }

            for (let key in payload) {
                if (payload[key] === null) {
                    loadGrid = false
                }
            }

            if (loadGrid) {
                try {
                    const capexPlanningRes = await this.$http.post(this.$ipCapexPlanning + 'capex-planning' + '/list', { ...payload })
                    if (capexPlanningRes) {
                        let _colHeaders = []
                        let _columns = []
                        let resColumns = capexPlanningRes.data.columns
                        this.formulas = capexPlanningRes.data.formulas

                        this.setDateColumns()

                        capexPlanningRes.data.headers.forEach((header) => {
                            let headerIndex = parseInt(header.value.slice(5, 7), 10)
                            let columnIndex = resColumns.findIndex(column => column.columnAlias === header.value)

                            let headerText = header.text 
                            if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                                if (header.value === 'value00') {
                                    let values = header.text.split(' ')
                                    headerText = `${values[0]} <br/> ${values[1] || ''}`
                                    // headerText = `${values[0]} ${values[1] || ''}`
                                }

                                let year = null
                                if (headerIndex > 0 && headerIndex <= 12) {
                                    if (this.months[headerIndex - 1].id >= this.startingMonthIndex + 1) {
                                        year = this.year1
                                    } else {
                                        year = this.year2
                                    }
                                    headerText = `${this.months[headerIndex - 1].text} <br/> ${year}`
                                    // headerText = `${this.months[headerIndex - 1].text} ${year}`
                                } else if (headerIndex >= 13 && headerIndex <= 24) {
                                    if (this.months[headerIndex - 1 - 12].id >= this.startingMonthIndex + 1) {
                                        year = this.year2
                                    } else {
                                        year = parseInt(this.year2, 10) + 1
                                    }
                                    headerText = `${this.months[headerIndex - 1 - 12].text} <br/> ${year}`
                                    // headerText = `${this.months[headerIndex - 1 - 12].text} ${year}`
                                }
                            }
                            _colHeaders.push(headerText)

                            let readOnly = false
                            if (header.editable === false) {
                                readOnly = true
                            }

                            let type = null
                            let className = []
                            if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                                type = 'numeric'
                                className.push('htRight')
                            } else {
                                type = 'text'
                                className.push('htLeft') 
                            }

                            let hide = false
                            if (header.hideColumn) {
                                hide = true
                            }

                            _columns.push({ data: header.value, readOnly, type, className, hide, columnLetter: header.columnLetter })
                        })
                        this.colHeaders = _colHeaders
                        this.columns = _columns

                        let _hiddenColumns = { columns: [], copyPasteEnabled: true }
                        _columns.forEach((column, index) => {
                            if (column.hide) {
                                _hiddenColumns.columns.push(index)
                            }
                        })
                        this.hiddenColumns = _hiddenColumns

                        let _rowHeaders = []
                        let _objectData = []

                        capexPlanningRes.data.rows.forEach((row, index) => {
                            _rowHeaders.push('')

                            let rowProps = {
                                index,
                                row,
                                className: '',
                                format: row.format,
                                readOnly: false,
                            }

                            let subtotalsQty = Object.entries(row).reduce((acc, cur) => {
                                return cur[1] && typeof cur[1] === 'string' && cur[1].includes('Subtotal -') ? acc + 1 : acc + 0
                            }, 0)

                            if (subtotalsQty > 1) {
                                let counter = 0
                                for (const field in row) {
                                    if (row[field] && typeof row[field] === 'string' && row[field].includes('Subtotal -')) {
                                        if (counter > 0) {
                                            row[field] = ''
                                        }

                                        counter += 1
                                    }
                                }
                            }

                            if (row.indent) {
                                rowProps.className += ' indentedRow'
                                row['account_group_description'] = ' '.repeat(row.indent) + row['account_group_description']
                            }

                            if (row.data_type === 'INT' || row.data_type === 'DECIMAL') {
                                for (let i = 0; i <= 24; i++) {
                                    if (i < 10) {
                                        row[`value0${i}`] = parseFloat(row[`value0${i}`], 10) || null
                                    } else {
                                        row[`value${i}`] = parseFloat(row[`value${i}`], 10) || null
                                    }
                                }
                            }

                            if (row.total > 0) {
                                rowProps.className += ' formulaRow'

                                let foundFormula = this.formulas.find(formula => formula.system_id === row.system_id)
                                let startIndex = this.formulas.indexOf(foundFormula)
                                let endIndex = this.formulas.indexOf(foundFormula) + 23

                                if (foundFormula) {
                                    for (let i = startIndex; i <= endIndex; i++) {
                                        row[this.formulas[i].column] = this.formulas[i].excelFormula
                                    }
                                }
                            }

                            if (row.css.length) {
                                rowProps.className += ` ${row.css}`
                            }

                            if (row.subtotal) {
                                rowProps.className += ` font-bold`
                            }

                            if (!row.editable) {
                                rowProps.readOnly = true
                            }

                            this.stylizedRows.push(rowProps)
                            _objectData.push(row)
                        })
                        this.rowHeaders = _rowHeaders
                        this.objectData = _objectData

                        if (!this.objectData.length) {
                            this.noDataMessage = 'Não há dados disponíveis'
                        }

                        this.loading = false
                    }
                } catch (err) {
                    this.loading = false
                    this.$fnError(err)
                }
            }
        },

        setDateColumns() {
            this.startingYear = true
            this.year1month =  true
            this.year1quarter =  true
            this.year1semester =  true
            this.laterYear =  true
            this.year2month =  true
            this.year2quarter =  true
            this.year2semester =  true
            
            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()
            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })
            this.months = _months
            this.year1months = this.months
            this.year2months = this.months
            this.year1quarters = this.quarters
            this.year2quarters = this.quarters
            this.year1semesters = this.semesters
            this.year2semesters = this.semesters
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }
            
            this.hotInstance.render()
        },

        showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 4; i++) {
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1quarter) {
                    this.year1quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year1quarters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2quarter) {
                    this.year2quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year2quarters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1quarters
            } else if ( year === 2){
                array = this.year2quarters
            }

            for (let i = 1; i <= 4; i++) {
                let foundQuarter = array.find(quarter => quarter.id === i)
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                if (foundQuarter) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }
            
            this.hotInstance.render()
        },

        showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 2; i++) {
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1semester) {
                    this.year1semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year1semesters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2semester) {
                    this.year2semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year2semesters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1semesters
            } else if ( year === 2){
                array = this.year2semesters
            }

            for (let i = 1; i <= 2; i++) {
                let foundSemester = array.find(semester => semester.id === i)
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                if (foundSemester) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }
            
            this.hotInstance.render()
        },

        showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = this.hotInstance.propToCol(`year${year}`)

            if (year === 1) {
                if (this.startingYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            } else if (year === 2) {
                if (this.laterYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.hotInstance.render()
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        async save() {
            let payload = { items: [] }

            for (let i = 0; i < this.objectData.length; i++) {
                let object = {}
                this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                    object[this.columns[index].data] = field
                })

                for (const row in this.payload) {
                    object[row] = this.payload[row]
                }
                
                payload.items.push(object)
            }

            try {
                const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning' + '/mass-save', { ...payload })
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.editedRows = []
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        setRefresh() {
            if (!this.unsavedChanges) {
                this.refresh(false)
            } else {
                this.dialogRefreshGrid = true
            }
        },

        async refresh(save) {
            this.loading = true
            this.dialogRefreshGrid = false

            if (save) {
                let payload = { items: [] }

                for (let i = 0; i < this.objectData.length; i++) {
                    let object = {}
                    this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                        object[this.columns[index].data] = field
                    })

                    for (const row in this.payload) {
                        object[row] = this.payload[row]
                    }

                    payload.items.push(object)
                }

                try {
                    const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning' + '/mass-save', { ...payload })
                    if (res) {
                        this.editedRows = []
                        this.hotInstance = null
                        this.loadGrid(this.payload)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            } else {
                this.hotInstance = null
                this.loadGrid(this.payload)
            }
        },

        closeGrid() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            }
        },

        setSelectEvent() {
            if (!this.unsavedChanges) {
                this.selectEvent(false)
            } else {
                this.dialogSelectEvent = true
            }
        },

        closeDialogSelectEvent() {
            this.event = this.backupSelectEvent
            this.dialogSelectEvent = false
        },

        async selectEvent(save) {
            if (save) {
                this.save()
            }

            this.dialogSelectEvent = false
            this.loading = true

            const purposeOption = this.options.find(option => option.column === 'id_purpose')
            purposeOption.items = []
            this.purpose = null
            this.payload.id_purpose = null

            const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
            costCenterOption.items = []
            this.costCenter = null
            this.payload.id_cost_center = null
            this.payload.id_event = this.event.id

            try {
                this.payload.id_account_group_version = this.event.id_account_group_version
                this.year1 = this.event.fiscal_year
                this.yearMonthInputStart = this.event.year_month_input_start
                this.startingMonthIndex = parseInt((this.event.year_month_input_start).toString().slice(4, 6)) - 1
                this.year2 = this.year1 + 1

                this.setDateColumns()
                
                let filter = {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_purpose_type",
                            operator: "=",
                            value: 2 // Despesas
                        },
                        {
                            AndOr: "AND",
                            column: "matrix",
                            operator: "=",
                            value: 0
                        }
                    ]
                }

                const purposeOption = this.options.find(option => option.column === 'id_purpose')
                const purposeRes = await this.$http.post(purposeOption.endpoint[0] + purposeOption.endpoint[1] + '/list-options', { filter })
                if (purposeRes.data.rows.length) {
                    purposeRes.data.rows.forEach((row, index) => {
                        purposeOption.items.push(row)
                        if (index === 0) {
                            this.purpose = row
                            this.payload.id_purpose = this.purpose.id
                        }
                    })
                    
                    filter = {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_purpose",
                                operator: "=",
                                value: this.payload.id_purpose
                            }
                        ]
                    }

                    const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
                    const costCenterRes = await this.$http.post(costCenterOption.endpoint[0] + costCenterOption.endpoint[1] + '/list-options', { filter })
                    if (costCenterRes.data.rows.length > 0) {
                        costCenterRes.data.rows.forEach((row, index) => {
                            costCenterOption.items.push(row)
                            if (index === 0) {
                                this.costCenter = row
                                this.payload.id_cost_center = this.costCenter.id_cost_center
                            }
                        })

                        this.refresh(false)
                    } else {
                        this.noCostCentersFound()
                    }
                } else {
                    this.noPurposesFound()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        noEventsFound() {
            this.event = -1
            this.payload.id_event = null
            this.purpose = -1
            this.payload.id_purpose = null
            this.costCenter = -1
            this.payload.id_cost_center = null
            this.noDataMessage = 'Cadastre um evento para iniciar o planejamento'
            this.noDataBtn.path = '/event'
            this.noDataBtn.text = 'Cadastrar evento'

            this.loading = false
        },

        setSelectPurpose() {
            if (!this.unsavedChanges) {
                this.selectPurpose(false)
            } else {
                this.dialogSelectPurpose = true
            }
        },

        closeDialogSelectPurpose() {
            this.purpose = this.backupSelectPurpose
            this.dialogSelectPurpose = false
        },

        async selectPurpose(save) {
            if (save) {
                this.save()
            }
        
            this.dialogSelectPurpose = false
            this.loading = true
            this.noDataMessage = null
            this.noDataBtn.path = { path: null, text: null }
            this.options.find(option => option.column === 'id_cost_center').items = []
            this.costCenter = null
            this.payload.id_cost_center = null
            this.payload.id_purpose = this.purpose.id

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "=",
                        value: this.payload.id_purpose
                    }
                ]
            }

            try {
                const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
                const costCenterRes = await this.$http.post(costCenterOption.endpoint[0] + costCenterOption.endpoint[1] + '/list-options', { filter })
                if (costCenterRes.data.rows.length > 0) {
                    costCenterRes.data.rows.forEach((row, index) => {
                        costCenterOption.items.push(row)
                        if (index === 0) {
                            this.costCenter = row
                            this.payload.id_cost_center = this.costCenter.id_cost_center
                        }
                    })
    
                    this.refresh(false)
                } else {
                    this.noCostCentersFound()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        noPurposesFound() {
            this.purpose = -1
            this.payload.id_purpose = null
            this.costCenter = -1
            this.payload.id_cost_center = null
            this.noDataMessage = 'Cadastre um próposito para iniciar o planejamento'
            this.noDataBtn.path = '/purpose'
            this.noDataBtn.text = 'Cadastrar propósito'

            this.loading = false
        },

        setSelectCostCenter() {
            if (!this.unsavedChanges) {
                this.selectCostCenter(false)
            } else {
                this.dialogSelectCostCenter = true
            }
        },

        closeDialogSelectCostCenter() {
            this.costCenter = this.backupSelectCostCenter
            this.dialogSelectCostCenter = false
        },

        selectCostCenter(save) {
            if (save) {
                this.save()
            }

            this.dialogSelectCostCenter = false
            this.loading = true
            this.payload.id_cost_center = this.costCenter.id_cost_center

            this.refresh()
        },

        noCostCentersFound() {
            this.costCenter = -1
            this.payload.id_cost_center = null
            this.noDataMessage = 'Cadastre um centro de custo para iniciar o planejamento'
            this.noDataBtn.path = '/purpose-cost-center'
            this.noDataBtn.text = 'Cadastrar centro de custo'

            this.loading = false

        },

        async exportSheet(){
            this.exporting = true

            let payload = this.payload

            try {
                const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        importSheet(){
            this.$refs.uploader.click()
            // window.addEventListener('focus', () => {}, { once: true })
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                try {
                    const uploadRes = await this.$http.post(this.$ipCapexPlanning + 'capex-planning' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        // const massEffectRes = await this.$http.post(this.$ipCapexPlanning + 'capex-planning' + '/mass-effect', { ...uploadRes.data.id })
                        // if (massEffectRes) {
                            this.$toast.success(uploadRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.refresh(false)
                        // }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },

        async updateMonths () {
            const payload = {
                id_event: this.event.id,
            }
            try {
                this.loadingUpdateMonths = true
                const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning/copy-from-actual', { ...payload })
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.loadingUpdateMonths = false
                    this.updateMonthsDialog = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingUpdateMonths = false
            }
            this.loadingUpdateMonths = false
            this.updateMonthsDialog = false
            this.refresh()
        },
    },
}
</script>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
